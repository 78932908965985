define(['app'], function (app)  {

  const coverageCalculator = () => {

    const component = {};

    const _config = {
      selectors: {
        input: 'input',
        metric: '[data-input="metric"]',
        coats: '[data-input="num-coats"]',
        wastage: '[data-input="include-wastage"]',
        calculateButton: '[data-button="calculate"]',
        resetButton: '[data-button="reset"]',
        addWallButton: '[data-button="addWall"]',
        addAreaButton: '[data-button="addArea"]',
        instructionsExpandToggle: '[data-button="expand"]',
        instructionsCollapseToggle: '[data-button="collapse"]',
        form: '[data-item="form"]',
        instructionsText: '[data-item="instructions"]',
        unitElement: '[data-item="units"]',
        wallsItems: '[data-items-container="wallsItems"]',
        areaItems: '[data-items-container="excludeAreasItems"]',
        resultsContainer: '[data-results="container"]',
        resultsQuantity: '[data-results="quantity"]',
        resultsArea: '[data-results="area"]',
        itemsToValidate: '[data-validate-input]',
        validationError: '.coverageCalculator_validationError',
        formItem: '.coverageCalculator_formItems',
        wallItem: '[data-item="wallItem"]',
        areaItem: '[data-item="areaItem"]'
      },

      settings: {
        walls: [],
        areas: [],
        allWallDimensions: [],
        allAreaDimensions: [],
        includeWastage: false
      },

      attributes: {
        calculatorType: 'data-calculator-type',
        paintCoverage: 'data-paint-coverage',
        wallpaperRollWidth: 'data-wallpaper-roll-width',
        wallpaperRollLength: 'data-wallpaper-roll-length',
        validationRequiredText: 'data-required-text',
        validationRegexText: 'data-validation-text',
        coveragePercentageText: 'data-coverage-percentage-text'
      },

      state: {
        isValid: false
      },
      objects: {
        originalWallItem: '',
        originalAreaItem: ''
      }
    };

    const _init = (element) => {
      component.element = element;
      component.checkCalculatorType();
      component.attachListeners();
      component.resetInstructionsState();
    };

    const _checkCalculatorType = () => {
      component.config.settings.calculatorType = component.element.getAttribute(component.config.attributes.calculatorType);
    };

    const _checkMetric = () => {
      component.config.settings.metric = component.element.querySelector(component.config.selectors.metric).value;
    };

    const _metricChangeListener = () => {
      let element = component.element.querySelector(component.config.selectors.metric);
      element.addEventListener('change', () => {
        component.checkMetric();
        component.updateUnits();
      })
    };

    const _updateUnits = () => {
      let unitElements = component.element.querySelectorAll(component.config.selectors.unitElement);
      let unitElementsArr = Array.prototype.slice.call(unitElements);

      unitElementsArr.forEach(el => {
        el.innerHTML = component.config.settings.metric;
      });

      component.config.settings.unit = component.config.settings.metric;
    };

    const _getPaintCoverage = () => {
      return parseFloat(component.element.getAttribute(component.config.attributes.paintCoverage));
    };

    const _getPaintCoats = () => {
      return parseFloat(component.element.querySelector(component.config.selectors.coats).value);
    };

    const _getWallpaperRollWidth = () => {
      return parseFloat(component.element.getAttribute(component.config.attributes.wallpaperRollWidth));
    };

    const _getWallpaperRollLength = () => {
      return parseFloat(component.element.getAttribute(component.config.attributes.wallpaperRollLength));
    };

    const _getWallsAndAreas = () => {
      let spaces = ['wall', 'area'];
      let selector;
      let arr;

      spaces.forEach(space => {
        selector = `[data-item="${space}Item"]`;
        arr = component.element.querySelectorAll(selector);
        component.config.settings[`${space}s`] = Array.prototype.slice.call(arr);
      });
    };

    const _getDimensions = (itemsArr, type, itemsDimensionsArr, metric) => {
      let heightSelector = `[data-input="${type}Height"]`;
      let widthSelector = `[data-input="${type}Width"]`;

      itemsArr.forEach(i => {
        let height = parseFloat(i.querySelector(heightSelector).value);
        let width = parseFloat(i.querySelector(widthSelector).value);
        let itemDimensions;

        if (!isNaN(height) && !isNaN(width)){
          if (metric !== 'm') {
            height = component.convertToMetres(height);
            width = component.convertToMetres(width);
          }

          itemDimensions = [height, width];
          itemsDimensionsArr.push(itemDimensions);
        }
      });
    };

    const _convertToMetres = (value) => {
      if (component.config.settings.metric === 'ft') {
        value *= 0.3048;
      } else {
        value *= 0.0254;
      }
      return value;
    };

    const _populateDimensionsArrays = () => {
      component.getDimensions(component.config.settings.walls, 'wall', component.config.settings.allWallDimensions, component.config.settings.metric);
      component.getDimensions(component.config.settings.areas, 'area', component.config.settings.allAreaDimensions, component.config.settings.metric);
    };

    const _calculateTotalArea = (itemsArr) => {
      let area;
      let totalArea = 0;

      for(let i=0; i < itemsArr.length; i++) {
        itemsArr[i].reduce((height, width) => {
          area = height * width;
          totalArea += area;
        })
      }

      return totalArea;
    };

    const _calculateTotalPaintReq = () => {
      let totalAreaWalls = component.calculateTotalArea(component.config.settings.allWallDimensions);
      let totalAreaAreas = component.calculateTotalArea(component.config.settings.allAreaDimensions);
      let coverage = component.getPaintCoverage();
      let coats = component.getPaintCoats();
      let totalPaintReq;

      if (totalAreaAreas === 0) {
        totalPaintReq = totalAreaWalls / (coverage / coats);
        component.config.settings.totalArea = totalAreaWalls;
      } else {
        totalPaintReq = (totalAreaWalls - totalAreaAreas) / (coverage / coats);
        component.config.settings.totalArea = totalAreaWalls - totalAreaAreas;
      }

      return totalPaintReq;
    };

    const _calculateTotalWallpaperReq = () => {
      let allWalls = component.config.settings.allWallDimensions;
      let rollWidth = component.getWallpaperRollWidth();
      let rollLength = component.getWallpaperRollLength();
      let wallpaperReqPerWall;
      let totalWallpaperReq = 0;
      let wallWidth;
      let wallHeight;

      for (let i=0; i < allWalls.length; i++) {
        wallHeight = allWalls[i][0];
        wallWidth = allWalls[i][1];

        wallpaperReqPerWall = ((wallWidth / rollWidth) * wallHeight) / rollLength;
        totalWallpaperReq += wallpaperReqPerWall;
      }

      component.config.settings.totalArea = component.calculateTotalArea(component.config.settings.allWallDimensions);
      return totalWallpaperReq;
    };

    const _setupCalculation = () => {
      component.getWallsAndAreas();
      component.populateDimensionsArrays();
    };

    const _getCoverage = () => {
      if (component.config.settings.calculatorType === 'Paint') {
        return component.calculateTotalPaintReq();
      } else {
        return component.calculateTotalWallpaperReq();
      }
    };

    const _addWallButtonListener = () => {
      let button = component.element.querySelector(component.config.selectors.addWallButton);

      button.addEventListener('click', () => {
        component.addItem(button.dataset.itemType, component.config.selectors.wallsItems);
      });
    };

    const _addAreaButtonListener = () => {
      let button = component.element.querySelector(component.config.selectors.addAreaButton);

      button.addEventListener('click', () => {
        component.addItem(button.dataset.itemType, component.config.selectors.areaItems);
      });
    };

    const _addItem = (itemType, cloneTarget) => {
      let itemSelector = `[data-item="${itemType}Item"]`;
      let itemToClone = component.element.querySelectorAll(itemSelector);

      let clonedItem = itemToClone[0].cloneNode(true);
      clonedItem.querySelector(`[data-input="${itemType}Height"]`).value = '';
      clonedItem.querySelector(`[data-input="${itemType}Width"]`).value = '';
      clonedItem.querySelector(".coverageCalculator_removeItemButtonContainer").style.display = 'flex';

      if (clonedItem.querySelector(component.config.selectors.validationError))
        clonedItem.querySelector(component.config.selectors.validationError).remove();

      component.element.querySelector(cloneTarget).appendChild(clonedItem);
      clonedItem.querySelector(component.config.selectors.input).focus();
    };

    const _removeWallOrAreaListener = () => {
      document.addEventListener('click', e => {
        let button = e.target.closest('button');

        if (button && (button.getAttribute("data-button") === "removeArea" || button.getAttribute("data-button") === "removeWall")) {
          let dataType = button.getAttribute("data-item-type");
          let dataItem = `[data-item="${dataType}Item"]`;
          let countItems = component.element.querySelectorAll(dataItem).length;

          if(countItems > 1) {
            button.closest(`[data-item="${dataType}Item"]`).remove();
          }
        }
      });
    };

    const _toggleInstructionsListener = () => {
      let expandButton = component.element.querySelector(component.config.selectors.instructionsExpandToggle);
      let collapseButton = component.element.querySelector(component.config.selectors.instructionsCollapseToggle);

      expandButton.addEventListener('click', () => {
        component.element.querySelector(component.config.selectors.instructionsText).style.display = 'flex';
        expandButton.style.display = 'none';
        collapseButton.style.display = 'flex';
      });

      collapseButton.addEventListener('click', () => {
        component.element.querySelector(component.config.selectors.instructionsText).style.display = 'none';
        expandButton.style.display = 'flex';
        collapseButton.style.display = 'none';
      });
    };

    const _isWastageRequiredListener = () => {
      let element = component.element.querySelector(component.config.selectors.wastage);
      element.addEventListener('change', () => {
        component.config.settings.includeWastage = !component.config.settings.includeWastage;
      });
    };

    const _addWastage = (coverage) => {
      coverage += (coverage * 0.1);
      return coverage;
    };

    const _populateResults = (coverage) => {
      let quantityEl = component.element.querySelector(component.config.selectors.resultsQuantity);
      let areaEl = component.element.querySelector(component.config.selectors.resultsArea);
      let totalArea = component.config.settings.totalArea.toFixed(1);
      const coveragePercentageText = areaEl.getAttribute(component.config.attributes.coveragePercentageText);
      let areaString;

      if (component.config.settings.includeWastage) {
        areaString = `${totalArea}${component.config.settings.unit}&sup2; ${coveragePercentageText}`;
      } else {
        areaString = `${totalArea}${component.config.settings.unit}&sup2;`;
      }

      quantityEl.innerHTML = coverage.toFixed(1);
      areaEl.innerHTML = areaString;

      component.showResultsContainer();
    };

    const _validateFields = () => {
      let fields = component.element.querySelectorAll(component.config.selectors.itemsToValidate);
      let invalidFields = [];

      component.config.state.isValid = false;

      // Delete All Error Messages
      component.resetValidationErrorMessage();

      fields.forEach(field => {
        if (field.value !== "" && field.dataset.validateInput === 'occasional') {

          if(component.regexCheck(field) === true) {
            invalidFields.push(field);
          }

        } else if (field.dataset.validateInput === 'true') {

          if (field.value === "") {
            component.renderValidationErrorMessage(field, component.element.getAttribute(component.config.attributes.validationRequiredText));
            invalidFields.push(field);
          } else {
            if(component.regexCheck(field) === true) {
              invalidFields.push(field);
            }
          }

        }
      });

      if (invalidFields.length === 0) {
        component.config.state.isValid = true;
      }
    };

    const _regexCheck = (field) => {
      const validationPattern = '^[0-9]\\d*(\\.\\d+)?$';

      if (!field.value.match(validationPattern)) {
        component.renderValidationErrorMessage(field, component.element.getAttribute(component.config.attributes.validationRegexText));
        return true;
      }
    };

    const _renderValidationErrorMessage = (element, message) => {
      let error = document.createElement("div");
      let closestFormItem = element.closest(component.config.selectors.formItem);
      error.classList.add("coverageCalculator_validationError");
      error.innerHTML = message;

      if (closestFormItem.querySelector(component.config.selectors.validationError) == null) {
        element.closest(component.config.selectors.formItem).appendChild(error);
      }

      closestFormItem.querySelector(component.config.selectors.input).focus();
    };

    const _resetValidationErrorMessage = () => {
      document.querySelectorAll(component.config.selectors.validationError).forEach(e => e.remove());
    };

    const _showResultsContainer = () => {
      let element = component.element.querySelector(component.config.selectors.resultsContainer);
      element.classList.add('show');
      component.scrollToElement(element);
    };

    const _hideResultsContainer = () => {
      let element = component.element.querySelector(component.config.selectors.resultsContainer);
      element.classList.remove('show');
    };

    const _scrollToElement = (element) => {
      element.scrollIntoView({behavior: "smooth",  block: "center"});
    };

    const _trimWhitespace = () => {
      let fields = component.element.querySelectorAll(component.config.selectors.itemsToValidate);

      fields.forEach(field => {
        field.value = field.value.trim();
      });
    };

    const _calculateButtonListener = () => {
      component.checkMetric();
      component.updateUnits();
      let button = component.element.querySelector(component.config.selectors.calculateButton);
      let coverage;

      button.addEventListener('click', () => {
        component.trimWhitespace();
        component.validateFields();

        if (component.config.state.isValid === false)
          return;

        component.setupCalculation();
        coverage = component.getCoverage();

        if (component.config.settings.includeWastage) {
          coverage = component.addWastage(coverage);
        }

        component.populateResults(coverage);
        component.resetComponentData();
      });
    };

    const _removeInputs = (itemName) => {
      component.element.querySelectorAll(itemName).forEach(item => item.remove());
    };

    const _resetButtonListener = () => {
      component.config.objects.originalWallItem = component.element.querySelectorAll(component.config.selectors.wallItem)[0];
      component.element.querySelector(component.config.selectors.resetButton).addEventListener('click', () => {

        let wallsItems = component.element.querySelector(component.config.selectors.wallsItems);
        component.removeInputs(component.config.selectors.wallItem);
        wallsItems.appendChild(component.config.objects.originalWallItem);

        if (component.config.settings.calculatorType === 'Paint') {
          component.config.objects.originalAreaItem = component.element.querySelectorAll(component.config.selectors.areaItem)[0];
          let areaItems = component.element.querySelector(component.config.selectors.areaItems);
          component.removeInputs(component.config.selectors.areaItem);
          areaItems.appendChild(component.config.objects.originalAreaItem);
        }

        component.element.querySelector(component.config.selectors.form).reset();
        component.resetComponentData();
        component.hideResultsContainer();
        component.config.settings.includeWastage = false;
        component.resetValidationErrorMessage();
        component.checkMetric();
        component.updateUnits();
        component.element.querySelector(component.config.selectors.input).focus();
      });
    };

    const _resetInstructionsState = () => {
      let instructionsItem = component.element.querySelector(component.config.selectors.instructionsText);
      let expandButton = component.element.querySelector(component.config.selectors.instructionsExpandToggle);
      let collapseButton = component.element.querySelector(component.config.selectors.instructionsCollapseToggle);

      if(window.innerWidth <= 768) {
        instructionsItem.style.display = 'none';
        expandButton.style.display = 'flex';
        collapseButton.style.display = 'none';
      } else {
        instructionsItem.style.display = 'flex';
        expandButton.style.display = 'none';
        collapseButton.style.display = 'none';
      }
    };

    const _onWindowResize = () => {
      window.addEventListener('resize', function() {
        component.resetInstructionsState();
      });
    };

    const _resetComponentData = () => {
      component.config.settings.walls = [];
      component.config.settings.areas = [];
      component.config.settings.allWallDimensions = [];
      component.config.settings.allAreaDimensions = [];
    };

    const _attachListeners = () => {
      component.calculateButtonListener();
      component.isWastageRequiredListener();
      component.addWallButtonListener();
      component.removeWallOrAreaListener();
      component.resetButtonListener();
      component.onWindowResize();
      component.metricChangeListener();

      if (component.config.settings.calculatorType === 'Paint') {
        component.addAreaButtonListener();
      }

      component.toggleInstructionsListener();
    };

    component.init = _init;
    component.config = _config;
    component.checkCalculatorType = _checkCalculatorType;
    component.checkMetric = _checkMetric;
    component.getPaintCoverage = _getPaintCoverage;
    component.getPaintCoats = _getPaintCoats;
    component.getWallpaperRollWidth = _getWallpaperRollWidth;
    component.getWallpaperRollLength = _getWallpaperRollLength;
    component.getWallsAndAreas = _getWallsAndAreas;
    component.getDimensions = _getDimensions;
    component.convertToMetres = _convertToMetres;
    component.calculateTotalArea = _calculateTotalArea;
    component.calculateTotalPaintReq = _calculateTotalPaintReq;
    component.calculateTotalWallpaperReq = _calculateTotalWallpaperReq;
    component.getCoverage = _getCoverage;
    component.setupCalculation = _setupCalculation;
    component.populateDimensionsArrays = _populateDimensionsArrays;
    component.isWastageRequiredListener = _isWastageRequiredListener;
    component.addWastage = _addWastage;
    component.calculateButtonListener = _calculateButtonListener;
    component.attachListeners = _attachListeners;
    component.addItem = _addItem;
    component.addWallButtonListener = _addWallButtonListener;
    component.addAreaButtonListener = _addAreaButtonListener;
    component.removeWallOrAreaListener = _removeWallOrAreaListener;
    component.resetButtonListener = _resetButtonListener;
    component.toggleInstructionsListener = _toggleInstructionsListener;
    component.resetComponentData = _resetComponentData;
    component.resetInstructionsState = _resetInstructionsState;
    component.onWindowResize = _onWindowResize;
    component.validateFields = _validateFields;
    component.renderValidationErrorMessage = _renderValidationErrorMessage;
    component.regexCheck = _regexCheck;
    component.resetValidationErrorMessage = _resetValidationErrorMessage;
    component.showResultsContainer = _showResultsContainer;
    component.hideResultsContainer = _hideResultsContainer;
    component.populateResults = _populateResults;
    component.scrollToElement = _scrollToElement;
    component.updateUnits = _updateUnits;
    component.metricChangeListener = _metricChangeListener;
    component.removeInputs = _removeInputs;
    component.trimWhitespace = _trimWhitespace;

    return component;
  };

  return coverageCalculator;
});
